import {translateString} from "../Components/Translator";
import {useContext} from "react";
import {CurrentUser} from "../App";

export function TextAreaComponent({config}) {
    const currentUser = useContext(CurrentUser);

    return (
        <div className={"row"}>
            <div>
                <textarea
                    className={"ct-form-textarea"}
                    readOnly={config.readOnly}
                    name={config.inputName}
                    placeholder={translateString(config.label, currentUser.language)}
                    defaultValue={config.value}
                    rows={config.rows ?? 7}
                    cols={config.cols ?? 100}
                />
            </div>
        </div>
    );

}