import {useContext} from "react";
import {CurrentUser} from "../App";
import {Query} from "../ApolloClient/Client";
import {ENABLE_SHIELD_QUERY} from "../ApolloClient/Queries/EnableShield";

export function ShieldComponent({cipherText}) {
    const currentUser = useContext(CurrentUser);
//Turn on shield
    /////////////////////////////

    const enableShield = async () => {
        let shield = Query(ENABLE_SHIELD_QUERY, "enableShield", {
            _secret: localStorage.getItem("esh"),
            _handshakeId: localStorage.getItem('hid')
        });

        if (shield === false) {
            return;
        }
        if (shield !== null && shield.message !== null) {
            localStorage.setItem("esh", null);
            localStorage.setItem('hid', null);

            return shield.message;
        }
    };


    enableShield().then((valll) => {
        console.log(valll);
        console.log(localStorage.getItem('dsh'));
    });

    ////////////////////////////
    return (
        <></>
    );
}
