import {ViewComponent} from "../Components/ViewComponent";
import {Translator} from "../Components/Translator";

const tabs = [
    {
        "id": 0,
        "icon": "fa-file-signature"
    }
];

const viewMenu = [{
    "tabName": <Translator tKey={"view.dsignatures.title"}/>,
    "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque fermentum nisi sed massa semper, eu lacinia nibh sagittis.",
}];

export function SigningComponent() {
    return (
        <ViewComponent data={viewMenu}/>
    );
}