import {gql} from "@apollo/client";

export const CREATE_INVITATION_QUERY = gql`
    query CreateInvitation{
        generateInvitation{
            code
            message
        }
    }
`;
