import {ViewComponent} from "../Components/ViewComponent";
import {Translator} from "../Components/Translator";
import App from "../Messenger/components/App";
import {Route, Routes} from "react-router-dom";
import {_404Component} from "./404Component";
import {useContext} from "react";
import {CurrentUser} from "../App";
import {AccessValidation} from "../Routers/UserRouter";
import {FriendsTable} from "./FriendsTable";
import {AddFriendForm} from "../Forms/AddFriendForm";
import FriendsComponent from "./FriendsComponent";

const tabs = [
    {
        "id": 0,
        "icon": "fa-solid fa-comments",
        "link": "/chat/messages/all"
    },
    {
        "id": 1,
        "icon": "fa-user-group",
        "link": "/chat/friends"
    },
    {
        "id": 2,
        "icon": "fa-user-plus",
        "link": "/chat/friends-inv"
    }
];

const viewMenu = [
    {
        "title": <Translator tKey={"view.chat.title"}/>,
        "content": <App/>,
    }
];

const viewMenuFriends = [
    {
        "title": <Translator tKey={"view.friends.title"}/>,
        "content": <FriendsTable/>,
    }
];


const viewMenuInvite = [
    {
        "content": <FriendsComponent/>,
    }
];

export function CommunicationComponent() {
    const currentUser = useContext(CurrentUser);

    if (!AccessValidation(currentUser.privileges, "user.messages.index")) {
        window.location = "/";
    }

    let styleChat = {
        height: "70rem"
    };

    let styleFriends = {
        height: "40rem"
    };

    return (
        <Routes>
            <Route path={"/messages/:thread"} element={<ViewComponent style={styleChat} data={viewMenu} tabs={tabs}/>}/>
            <Route path={"/friends"}
                   element={<ViewComponent style={styleFriends} data={viewMenuFriends} tabs={tabs}/>}/>
            <Route path={"/friends-inv"}
                   element={<ViewComponent style={styleFriends} data={viewMenuInvite} tabs={tabs}/>}/>

            <Route path={"*"} element={<_404Component/>}/>
        </Routes>
    );
}