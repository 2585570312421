import {gql} from "@apollo/client";

export const ME_QUERY = gql`
    query MeQuery{
        me{
            id
            name
            email
            language
            activated
            privileges {
                name
            }
            menuNotifications {
                name
                count
            }
            account {
                netAmount
                inCurrencyAmount
                currency{
                    symbol
                }
            }
        }
    }
`;
