import {ViewComponent} from "../Components/ViewComponent";
import {Translator} from "../Components/Translator";
import {Route, Routes} from "react-router-dom";
import {_404Component} from "./404Component";

const tabs = [
    {
        "id": 0,
        "icon": "fa-handshake",
        "link": "/products/individual"
    },
    {
        "id": 1,
        "icon": "fa-wifi",
        "link": "/products/network"
    },
    {
        "id": 2,
        "icon": "fa-microchip",
        "link": "/products/hardware"
    }
];

const product = [
    {
        "title": <Translator tKey={"view.products.title.individual"}/>,
        "content": "Już wkrótce",
    }
];

const productNetwork = [
    {
        "title": <Translator tKey={"view.products.title.network"}/>,
        "content": "Już wkrótce",
    }
];

const productHardware = [
    {
        "title": <Translator tKey={"view.products.title.hardware"}/>,
        "content": "Już wkrótce",
    }
];

export function ProductComponent() {
    return (
        <Routes>
            <Route path={"/individual"} element={<ViewComponent data={product} tabs={tabs}/>}/>
            <Route path={"/network"} element={<ViewComponent data={productNetwork} tabs={tabs}/>}/>
            <Route path={"/hardware"} element={<ViewComponent data={productHardware} tabs={tabs}/>}/>

            <Route path={"*"} element={<_404Component/>}/>
        </Routes>
    );
}