import React, {useContext, useEffect, useState} from 'react';
import Compose from '../Compose';
import Toolbar from '../Toolbar';
import ToolbarButton from '../ToolbarButton';
import Message from '../Message';
import moment from 'moment';

import './MessageList.css';
import axios from "axios";
import {CurrentUser} from "../../../App";
import {useParams} from "react-router-dom";


export default function MessageList(props) {
    const [messages, setMessages] = useState([]);
    const {thread} = useParams();

    const currentUser = useContext(CurrentUser);
    useEffect(() => {
        getMessages();
    }, [])


    const getMessages = () => {
        const token = localStorage.getItem('bearer-token');

        if (thread === "all") {
            return;
        }

        axios.get('http://localhost/api/messenger/threads/' + thread + '/messages', {
            headers: {
                "X-Authorization": "Bearer " + token
            }
        }).then(response => {
            let tempMessages = response.data.data.map(result => {
                return {
                    id: result.id,
                    author: result.owner_id,
                    message: result.body,
                    timestamp: result.created_at
                };
            });
            let m = [...messages, ...tempMessages];
            m.reverse();
            setMessages(m);
        }).catch(error => {
            return;
            window.location = "/";
        });
    }

    const renderMessages = () => {
        let i = 0;
        let messageCount = messages.length;
        let tempMessages = [];

        while (i < messageCount) {
            let previous = messages[i - 1];
            let current = messages[i];
            let next = messages[i + 1];
            let isMine = parseInt(current.author) === parseInt(currentUser.id);
            let currentMoment = moment(current.timestamp);
            let prevBySameAuthor = false;
            let nextBySameAuthor = false;
            let startsSequence = true;
            let endsSequence = true;
            let showTimestamp = true;

            if (previous) {
                let previousMoment = moment(previous.timestamp);
                let previousDuration = moment.duration(currentMoment.diff(previousMoment));
                prevBySameAuthor = previous.author === current.author;

                if (prevBySameAuthor && previousDuration.as('hours') < 1) {
                    startsSequence = false;
                }

                if (previousDuration.as('hours') < 1) {
                    showTimestamp = false;
                }
            }

            if (next) {
                let nextMoment = moment(next.timestamp);
                let nextDuration = moment.duration(nextMoment.diff(currentMoment));
                nextBySameAuthor = next.author === current.author;

                if (nextBySameAuthor && nextDuration.as('hours') < 1) {
                    endsSequence = false;
                }
            }

            tempMessages.push(
                <Message
                    key={i}
                    isMine={isMine}
                    startsSequence={startsSequence}
                    endsSequence={endsSequence}
                    showTimestamp={showTimestamp}
                    data={current}
                />
            );

            // Proceed to the next message.
            i += 1;
        }

        return tempMessages;
    }

    return (
        <div className="message-list">
            <Toolbar title="Conversation"/>

            <div className="message-list-container">{renderMessages()}</div>

            <Compose rightItems={[
                <ToolbarButton key="emoji" icon="fa-solid fa-heart"/>,
                <ToolbarButton key="image" icon="fa-solid fa-image"/>,
                <ToolbarButton key="file" icon="fa-solid fa-file"/>
            ]}/>
        </div>
    );
}