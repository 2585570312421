import React from 'react';
import './Compose.css';
import axios from "axios";
import {useParams} from "react-router-dom";

export default function Compose(props) {
    const {thread} = useParams();

    return (
        <div className="_compose">
            <input
                onKeyDown={
                    event => {
                        if (event.key === "Enter") {
                            console.log(event.target.value);


                            let payload = {
                                "message": event.target.value,
                                "temporary_id": "123-456-789"
                            }

                            const token = localStorage.getItem('bearer-token');
                            axios.post('http://localhost/api/messenger/threads/' + thread + '/messages', payload, {
                                headers: {
                                    "X-Authorization": "Bearer " + token
                                }
                            }).then(response => {
                                console.log(response);
                                return;
                                let tempMessages = response.data.data.map(result => {
                                    return {
                                        id: result.id,
                                        author: result.owner_id,
                                        message: result.body,
                                        timestamp: result.created_at
                                    };
                                });
                                //setMessages([...messages, ...tempMessages])
                            }).catch(error => {
                                console.log(error);
                                return;
                                window.location = "/";
                            });


                            event.target.value = "";
                        }
                    }
                }
                type="text"
                className="_compose-input ct-form-text-input"
                placeholder="Type a message, @name"
            />&nbsp;

            {
                props.rightItems
            }
        </div>
    );
}