import {ViewComponent} from "../Components/ViewComponent";
import {Translator} from "../Components/Translator";
import {SingleDecryptCryptoForm} from "../Forms/SingleDecryptCryptoForm";
import {Route, Routes} from "react-router-dom";
import {_404Component} from "./404Component";
import {SingleEncryptCryptoForm} from "../Forms/SingleEncryptCryptoForm";

const tabs = [
    {
        "id": 0,
        "icon": "fa-fingerprint",
        "link": "/crypto/single/encrypt"
    }, {
        "id": 1,
        "icon": "fa-fingerprint",
        "link": "/crypto/single/decrypt"
    }
];

const viewMenuEncrypt = [{
    "title": <Translator tKey={"view.encryption.title"}/>,
    "content": <SingleEncryptCryptoForm/>,
}];

const viewMenuDecrypt = [{
    "title": <Translator tKey={"view.decryption.title"}/>,
    "content": <SingleDecryptCryptoForm/>,
}];

export function SingleCryptoComponent() {
    let styleEncrypt = {
        height: "50rem"
    };
    let styleDecrypt = {
        height: "50rem"
    };

    return (
        <Routes>
            <Route path={"/single/encrypt"}
                   element={<ViewComponent style={styleEncrypt} data={viewMenuEncrypt} tabs={tabs}/>}/>
            <Route path={"/single/decrypt"}
                   element={<ViewComponent style={styleDecrypt} data={viewMenuDecrypt} tabs={tabs}/>}/>
            <Route path={"*"} element={<_404Component/>}/>
        </Routes>
    );
}