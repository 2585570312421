import {ViewComponent} from "../Components/ViewComponent";
import {Translator} from "../Components/Translator";

const tabs = [
    {
        "id": 0,
        "icon": "fa-phone"
    }
];
const viewMenu = [{
    "title": <Translator tKey={"view.contact.title"}/>,
    "content": "",
}];

export function KontaktComponent() {
    return (
        <ViewComponent data={viewMenu} tabs={tabs}/>
    );
}