import './App.css';
import CryptonFooter from "./Views/FooterComponent";
import React, {createContext} from "react";
import MainMenuComponent from "./Components/MainMenuComponent";
import {Route, Routes} from "react-router-dom";
import {AdminRouter} from "./Routers/AdminRouter";
import {UserRouter} from "./Routers/UserRouter";
import {Query} from "./ApolloClient/Client";
import {ME_QUERY} from "./ApolloClient/Queries/Me";
import {startWebsocketClient} from "./EchoClient/EchoClient";

import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {faFontAwesome, faTwitter} from '@fortawesome/free-brands-svg-icons'

library.add(fas, faTwitter, faFontAwesome)

export const CurrentUser = createContext(
    {
        id: 0,
        loggedIn: false,
        privileges: [],
        name: "Guest",
        email: "",
        activated: false,
        language: "pl",
        menuNotifications: [],
        account: {
            netAmount: 0.0,
            inCurrencyAmount: 0.0,
            currency: {
                symbol: ''
            }
        }
    }
);


function App() {

    let defaultUser = {
        id: 0,
        loggedIn: false,
        privileges: [],
        name: "Guest",
        email: "",
        activated: false,
        language: "pl",
        menuNotifications: [],
        account: {
            netAmount: 0.0,
            inCurrencyAmount: 0.0,
            currency: {
                symbol: ''
            }
        }
    };


    let me = Query(ME_QUERY, "me");
    if (me === false) {
        return; // graphQl connection is not ready
    }
    if (me !== null && me.id !== undefined) {
        let __privileges = [];
        for (let i = 0; i < me.privileges.length; i++) {
            __privileges.push(me.privileges[i].name);
        }

        let _loggedIn = me.id > 0;

        defaultUser =
            {
                id: me.id,
                loggedIn: me.id > 0,
                privileges: __privileges,
                name: me.name,
                language: me.language,
                email: me.email,
                activated: false,
                menuNotifications: me.menuNotifications,
                account: {
                    netAmount: me.account.netAmount,
                    inCurrencyAmount: me.account.inCurrencyAmount,
                    currency: {
                        symbol: me.account.currency.symbol
                    }
                }
            };

        if (_loggedIn) {
            startWebsocketClient(defaultUser);
        }
    }

    return (
        <CurrentUser.Provider value={defaultUser}>
            <div className="App">
                {/*<Menubar/>*/}
                <div className={"mainPanel row"}>
                    <div className="menuPanel">
                        <MainMenuComponent/>
                    </div>
                    <div className={"contentPanel"}> {/*col-sm-6 col-md-9 col-xl-10*/}
                        <Routes>
                            <Route path={"/admin/*"} element={<AdminRouter/>}/>
                            <Route path={"*"} element={<UserRouter/>}/>
                        </Routes>
                    </div>

                </div>
                <CryptonFooter/>
            </div>
        </CurrentUser.Provider>
    );
}

export default App;

export function CheckAccess(privileges, needle) {
    try {
        privileges.forEach(function (element) {
            if (element === needle) {
                throw new Error();
            }
            if (element.name === needle) {
                throw new Error();
            }
        });

        return false;
    } catch (e) {
    }

    return true;
}

export function HasAdminAccess(privileges) {
    try {
        privileges.forEach(function (element) {
            if (element === "IS_ADMIN") {
                throw new Error();
            }
            if (element.name === "IS_ADMIN") {
                throw new Error();
            }
        });

        return false;
    } catch (e) {
    }

    return true;
}

export function HasModeratorAccess(privileges) {
    try {
        privileges.forEach(function (element) {
            if (element === "IS_MODERATOR") {
                throw new Error();
            }
            if (element.name === "IS_MODERATOR") {
                throw new Error();
            }
        });

        return false;
    } catch (e) {
    }

    return true;
}


