import {gql} from "@apollo/client";

export const GET_USERS = gql`
    query getUsers {
        users{
            data{
                id
                account {
                    netAmount
                    inCurrencyAmount
                    currency{
                        symbol
                    }
                }
                activated
                created_at
                email
                email_verified_at
                language
                name
                privileges {
                    name
                }
                menuNotifications {
                    name
                    count
                }
            }
        }
    }
`;