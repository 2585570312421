import {useState} from "react";
import './ViewComponent.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

// Utility function
const isSingleTab = (data) => data === undefined || data.length === 1;

export function ViewComponent({data, viewName, style, tabs}) {
    const isSingle = isSingleTab(tabs);

    return (
        <div className="ct-view-container">
            <div style={style} className="ct-view-main">
                <TabMenu data={tabs} viewName={viewName} isSingle={isSingle}/>
                <Content data={data} isSingle={isSingle}/>
            </div>
        </div>
    );
}

const TabMenu = ({data, viewName, isSingle}) => {
    if (!data) return null;

    const renderOptions = (single) => {
        return data.map(({icon, link, id}) => {
            const buttonClasses = single ? "ct-view-button-single" : "ct-view-button";
            const iconClassName = "fa-solid " + icon;

            if (link) {
                return (
                    <Link key={id} className={buttonClasses} to={link}>
                        <button className={buttonClasses}>
                            <FontAwesomeIcon icon={iconClassName} className="ct-icon ct-view-icon"/>
                        </button>
                    </Link>
                );
            }

            return (
                <button key={id} className={buttonClasses}>
                    <FontAwesomeIcon icon={iconClassName} className="ct-icon ct-view-icon"/>
                </button>
            );
        })
    }

    const topLevelClass = !isSingle ? <h3 className='ct-view-title'>{viewName}</h3> : null;

    return (
        <div>
            {topLevelClass}
            <div className="ct-view-box">
                {renderOptions(isSingle)}
            </div>
        </div>
    );
}

const Content = ({data, isSingle}) => {
    const title = isSingle ? <h2>{data?.[0]?.title}</h2> : <h4>{data?.[0]?.title}</h4>;
    return (
        <div key="content-div" className="ct-view-content row">
            {title}
            <center key="content-center" className="ct-view-center">
                {data?.[0]?.content}
            </center>
        </div>
    );
}