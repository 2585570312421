import React, {useState, useEffect} from 'react';
import ConversationSearch from '../ConversationSearch';
import ConversationListItem from '../ConversationListItem';
import Toolbar from '../Toolbar';
import ToolbarButton from '../ToolbarButton';
import axios from 'axios';

import './ConversationList.css';

export default function ConversationList(props) {
    const [conversations, setConversations] = useState([]);
    useEffect(() => {
        getConversations()
    }, [])

    const getConversations = () => {
        const token = localStorage.getItem('bearer-token');
        axios.get('http://localhost/api/messenger/threads', {
            headers: {
                "X-Authorization": "Bearer " + token
            }
        }).then(response => {
            let newConversations = response.data.data.map(result => {
                return {
                    id: result.id,
                    photo: "http://localhost/logo_star.png",
                    name: result.name,
                    text: result.resources.latest_message.body
                };
            });
            setConversations([...conversations, ...newConversations])
        }).catch(error => {
            return;
            window.location = "/";
        });
    }

    return (
        <div className="conversation-list">
            <Toolbar
                title="Messenger"
                leftItems={[
                    <ToolbarButton key="cog" icon="fa-solid fa-cog"/>
                ]}
                rightItems={[
                    <ToolbarButton key="add" icon="fa-solid fa-plus-circle"/>
                ]}
            />
            <ConversationSearch/>
            {
                conversations.map(conversation =>
                    <ConversationListItem
                        key={conversation.id}
                        data={conversation}
                    />
                )
            }
        </div>
    );
}