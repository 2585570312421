import {FormComponent} from "../Components/FormComponent";
import {Translator} from "../Components/Translator";
import {useContext} from "react";
import {CheckAccess, CurrentUser} from "../App";
import {GET_USER} from "../ApolloClient/Queries/User";
import {Query} from "../ApolloClient/Client";
import {useParams} from "react-router-dom";
import {useMutation} from "@apollo/client";
import {UPDATE_USER_MUTATION} from "../ApolloClient/Mutations/UpdateUser";
import {UPDATE_USER_WITH_ID_MUTATION} from "../ApolloClient/Mutations/UpdateUserWithId";
import {languageFormatter, languageSelectFormatter} from "../Components/Formatter";
import {GET_PRIVILEGES} from "../ApolloClient/Queries/Privileges";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const configuration = {
    "title": <Translator tKey={"form.title.account"}/>,
    "inputs": [
        {
            "type": "text",
            "cssClass": "",
            "inputName": "name",
            "icon": <FontAwesomeIcon icon={"fa-solid fa-user-secret"} className="blueIcon registerIcon ct-icon"></FontAwesomeIcon>
        },
        {
            "type": "text",
            "cssClass": "",
            "inputName": "email",
            "icon": <FontAwesomeIcon icon={"fa-solid fa-at"} className="blueIcon registerIcon ct-icon"></FontAwesomeIcon>
        },
        {
            "type": "select",
            "cssClass": "",
            "inputName": "language",
            "icon": <FontAwesomeIcon icon={"fa-solid fa-language"} className={"blueIcon registerIcon ct-icon"}/>,
            "formatter": languageSelectFormatter,
            "options": [
                {
                    "id": "pl",
                    "text": "languages.pl",
                    "selected": true
                },
                {
                    "id": "eng",
                    "text": "languages.eng",
                }

            ]
        },
        {
            "type": "table_select",
            "cssClass": "",
            "title": "form.user.privileges",
            "inputName": "privileges",
            "adminOnly": true,
            "colSize": 6,
            "objectFields": [
                {"field": "id", "title": "ID"},
                {
                    "field": "name",
                    "title": <Translator tKey={'active'}/>,
                    "formatter": null,
                },
            ],
            "graphql": {
                "query": GET_PRIVILEGES,
                "queryField": "privileges"
            }
        }
    ],
    "name": "account_from",
    "buttons": [
        {
            "type": "save"
        },
    ]
}

export function AccountForm({isAdminMode}) {
    const currentUser = useContext(CurrentUser);

    const [updateUserMutation, {us_data, us_loading, us_error}] = useMutation(UPDATE_USER_MUTATION);
    const [updateUserWithIdMutation, {
        us_wi_data,
        us_wi_loading,
        us_wi_error
    }] = useMutation(UPDATE_USER_WITH_ID_MUTATION);

    const {id} = useParams();

    const handleRegister = (data) => {
        /*console.log(data);
        return;
        */
        if (isAdminMode) {
            updateUserWithIdMutation({
                variables: {
                    _email: data.email,
                    _name: data.name,
                    _id: id,
                    _language: data.language,
                    _privileges: data.privileges
                }
            }).then(
                (r) => {
                    let operation = r.data.updateUserWithId;
                    if (operation.code === 0) {
                        window.location = "/admin/user/" + id;
                    }
                }
            ).catch(
                (r) => {
                    console.log(r);
                }
            )
        } else {
            updateUserMutation({
                variables: {
                    _email: data.email,
                    _name: data.name,
                    _language: data.language
                }
            }).then(
                (r) => {
                    let operation = r.data.updateUser;
                    if (operation.code === 0) {
                        window.location = "/account/settings";
                    }
                }
            ).catch(
                (r) => {
                    console.log(r);
                }
            )
        }
    };


    if (isAdminMode) {
        const selectedUser = Query(GET_USER, "user", {_id: id});

        if (selectedUser === false) {
            return;
        }

        const privilegeFormatter = (val) => {
            let checked = false;

            if (CheckAccess(selectedUser.privileges, val)) {
                checked = true;
            }

            return (
                <>
                    <input defaultChecked={checked}
                           key={val + "_input"}
                           multiple={true}
                           name={"privileges"}
                           className={" ct-form-checkbox"}
                           type={"checkbox"}
                           defaultValue={val}
                    />
                    {val}
                </>
            );
        };

        configuration.inputs[0].value = selectedUser.name;
        configuration.inputs[1].value = selectedUser.email;
        configuration.inputs[2].value = selectedUser.language;
        configuration.inputs[3].objectFields[1].formatter = privilegeFormatter;
    } else {
        configuration.inputs[0].value = currentUser.name;
        configuration.inputs[1].value = currentUser.email;
        configuration.inputs[2].value = currentUser.language;
        configuration.inputs.pop();//diasble privileges
    }

    return (
        <FormComponent config={configuration} onSubmitCallback={handleRegister}/>
    );
};