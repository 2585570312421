import {gql} from "@apollo/client";

export const GET_USER = gql`
    query getUser($_id: ID!) {
        user(id: $_id){

            id
            account {
                netAmount
            }
            activated
            created_at
            email
            email_verified_at
            language
            name
            privileges {
                name
            }
            menuNotifications {
                name
                count
            }
        }
    }

`;