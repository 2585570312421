import {ViewComponent} from "../Components/ViewComponent";
import {HomeStatic} from "../Statics/HomeStatic";

const tabs = [
    {
        "id": 0,
        "icon": "fa-house-lock"
    }
];

const viewMenu = [{
    "title": "crypt0n",
    "content": <HomeStatic/>,
}];

export function HomePageComponent() {
    return (
        <ViewComponent data={viewMenu} tabs={tabs}/>
    );
}