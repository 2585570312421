import './Forms.css'

import {useLazyQuery} from "@apollo/client";
import {LOGIN_QUERY} from "../ApolloClient/Queries/Login";
import {Translator} from "../Components/Translator";
import {FormComponent} from "../Components/FormComponent";
import {useCookies} from 'react-cookie'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const configuration = {
    "title": <Translator tKey={"form.title.signin"}/>,
    "inputs": [
        {
            "type": "email",
            "cssClass": "",
            "inputName": "login",
            "icon": <FontAwesomeIcon icon={"fa-solid fa-at"} className={"blueIcon registerIcon ct-icon"}/>,
            "placeholder": "form.register.email",
            "required": true,
        },
        {
            "type": "password",
            "cssClass": "",
            "inputName": "password",
            "icon": <FontAwesomeIcon icon={"fa-solid fa-lock"} className={"blueIcon registerIcon ct-icon"}/>,
            "placeholder": "form.register.password",
            "required": true,
        },
    ],
    "name": "login_from",
    "buttons": [
        {
            "type": "login",
        },
        {
            "type": "forgot",
        },
    ],
}


export default function LoginForm() {
    const [cookies, setCookie] = useCookies(['access_token']);
    const [logInto, {loading, error, data}] = useLazyQuery(LOGIN_QUERY);
    if (loading) {
        return;
    }

    if (error) {
        return;
    }

    const handleLogin = (data) => {
        if (data.password === "" || data.login === "") {
            window.location = "/online/login";
            return;
        }

        let l = logInto({variables: {_email: data.login, _password: data.password}});

        l.then(function (response) {

            let login = response.data.login;
            if (login === null) {
                window.location = "/login";
                return;
            }
            let token = login.token;

            localStorage.setItem("bearer-token", token);
            let expires = new Date();
            expires.setTime(expires.getTime() + (60 * 1000));
            setCookie('access_token', token, {path: '/', expires});

            window.location = "/handshake";
        });
    };

    return (
        <FormComponent config={configuration} onSubmitCallback={handleLogin}/>
    );
}