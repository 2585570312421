import {useContext} from "react";
import {CurrentUser} from "../App";
import {translateString} from "../Components/Translator";

export function TextInputComponent({config}) {
    const currentUser = useContext(CurrentUser);

    return (
        <div className={"row"}>
            <div>
                {config.icon}
                <input
                    placeholder={translateString(config.placeholder,currentUser.language)}
                    required={config.required}
                    name={config.inputName}
                    className={config.cssClass + " ct-form-text-input"}
                    type={config.type}
                    defaultValue={config.value}
                />
            </div>
        </div>
    );
}