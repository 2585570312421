import {useContext} from "react";
import {CurrentUser} from "../App";
import {translateString} from "../Components/Translator";

export function FileInputComponent({config, fileHandler}) {
    const currentUser = useContext(CurrentUser);

    const validFile = ({
                   target: {
                       validity,
                       files: [file],
                   },
               }) => {
        if (validity.valid){
            console.log(file);
            return file;
        }
    }

    const handleFileChange = (e) => {
        if (e.target.files) {
            fileHandler(validFile(e));
        }
    };

    return (
        <div className={"row"}>
            <div>
                {config.icon}
                <input
                    placeholder={translateString(config.placeholder, currentUser.language)}
                    required={config.required}
                    type={config.type}
                    onChange={handleFileChange}
                />
            </div>
        </div>
    );
}