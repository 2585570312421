import {useContext} from "react";
import {CurrentUser} from "../App";
import {eng_language} from "../Languages/eng";
import {pl_language} from "../Languages/pl";

export const translateString = (key, lang) => {
    let languageBase;

    switch (lang) {
        case "-":
            return "";
        case "eng":
            languageBase = eng_language;
            break;
        case "pl":
        default:
            languageBase = pl_language;
    }

    try {
        let keyParts = key.split(".");

        keyParts.forEach(function (element) {
            languageBase = languageBase[element];
        });
        if (languageBase === undefined) {
            return key;
        }
        return languageBase;

    } catch (e) {
        return key;
    }
}

export function Translator({tKey}) {
    const currentUser = useContext(CurrentUser);

    return (
        <>
            {translateString(tKey, currentUser.language)}
        </>
    );
}