import {gql} from "@apollo/client";

export const GET_PRIVILEGES = gql`
    query getPrivileges {
        privileges{
            data{
                id
                name
            }
        }
    }
`;