import {ViewComponent} from "../Components/ViewComponent";
import {Translator} from "../Components/Translator";

const tabs = [
    {
        "id": 0,
        "icon": "fa-dollar-sign"
    }
];

const viewMenu = [{
    "title": <Translator tKey={"view.payments.title"}/>,
    "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque fermentum nisi sed massa semper, eu lacinia nibh sagittis.",
}];

export function PaymentsComponent() {

    return (
        <ViewComponent data={viewMenu} tabs={tabs}/>
    );
}