import {gql} from "@apollo/client";

export const HANDSHAKE_INIT_QUERY = gql`
    query handshakeInit{
        handshakeInit{
            publicRSAKey
            handshakeId
        }
    }
`;
