import {TextInputComponent} from "../Inputs/TextInputComponent";
import {CheckBoxComponent} from "../Inputs/CheckBoxComponent";
import {SelectComponent} from "../Inputs/SelectComponent";
import {ButtonComponent} from "../Inputs/ButtonComponent";
import '../Forms/Forms.css';
import {TextAreaComponent} from "../Inputs/TextAreaComponent";
import {SelectListComponent} from "../Inputs/SelectListComponent";
import {isArray} from "@apollo/client/utilities";
import {FileInputComponent} from "../Inputs/FileInputComponent";
import {useContext, useRef, useState} from "react";
import {translateString, Translator} from "./Translator";
import {CurrentUser} from "../App";

export const ERROR_KEY = "ct-form-errors-id";

export function FormComponent({config, onSubmitCallback}) {
    const [fileList, setFileList] = useState([]);
    const currentUser = useContext(CurrentUser);
    const errorsContainer = useRef(<div></div>);

    const fileHandler = (file) => {
        setFileList([...fileList, file]);
    };

    const mapInputs = (config) => {

        return config.inputs.map((config) => {
            let id = '_' + config.inputName + '_id';
            switch (config.type) {
                case "password":
                case "text":
                case "email":
                    return <TextInputComponent key={id} config={config}/>;
                case "checkbox":
                    return <CheckBoxComponent key={id} config={config}/>;
                case "select":
                    return <SelectComponent key={id} config={config}/>;
                case "textarea":
                    return <TextAreaComponent key={id} config={config}/>;
                case "table_select":
                    return <SelectListComponent key={id} config={config}/>;
                case "file":
                    return <FileInputComponent key={id} config={config} fileHandler={fileHandler}/>;
                default:
                    return <div key={"default"}></div>;
            }
        });
    };

    const mapButtons = (config) => {
        return config.buttons.map((config) => {
            let id = '_' + config.type + '_id';
            return <ButtonComponent key={id} config={config}/>;
        });
    };

    const writeErrors = (error, formatter) => {
        let obj = errorsContainer.current;
        if (formatter === undefined || formatter === null) {
            obj.innerHTML = translateString(error.message,currentUser.language);
        }else{
            obj.innerHTML = formatter(error);
        }
    };

    const submitHandler = (e) => {
        e.preventDefault();

        const form = e.target;
        const formData = new FormData(form);

        let data = {};
        let entries = formData.entries();

        let currValue = entries.next();

        while (!currValue.done) {
            let key = currValue.value[0];
            let val = currValue.value[1];
            if (data[key] === undefined) {
                data[key] = val;
                data.length++;
            } else if (isArray(data[key])) {
                data[key].push(val);
            } else {
                let tmp = data[key];
                data[key] = [];
                data[key].push(tmp);
                data[key].push(val);
            }
            currValue = entries.next();
        }

        const formJson = JSON.parse(JSON.stringify(data));

        onSubmitCallback(formJson, fileList, writeErrors);
    }

    return (
        <div key={"ct-form-panel-id"} className={"ct-form-panel"}>
            <form method="post" onSubmit={submitHandler}>
                <h4>{config.title}</h4>
                {mapInputs(config)}
                <div key={ERROR_KEY} className={"ct-form-errors"} ref={errorsContainer}>
                </div>
                <div key={"ct-form-buttons-id"} className={"ct-form-buttons"}>
                    {mapButtons(config)}
                </div>
            </form>
        </div>
    );
}