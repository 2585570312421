import './FooterComponent.css';
import {FriendsTable} from "./FriendsTable";
import {AddFriendForm} from "../Forms/AddFriendForm";
import {CreateInvitationForm} from "../Forms/CreateInvitationForm";

export default function FriendsComponent() {
    return (
        <>
            <AddFriendForm/>
            <br/>
            <CreateInvitationForm/>
        </>
    );
}