import './TableComponent.css';
import {Link} from "react-router-dom";
import {Query} from "../ApolloClient/Client";
import {useState} from "react";

export default function TableComponent({
                                           useAsForm,
                                           tableName,
                                           renderHeaderFnc,
                                           renderRowsFnc,
                                           renderHeaderItemFnc,
                                           renderRowItemFnc,
                                           query,
                                           queryField,
                                           fields,
                                           urlBase,
                                           useMockData,
                                           paginationCallback
                                       }) {
    const [objectItems, setObjectItems] = useState();

    if (useMockData !== undefined) {
        setObjectItems(useMockData);
    } else {
        if (query !== undefined && queryField !== undefined) { // Dane z GraphQL
            let queryResult = Query(query, queryField);

            if (queryResult === false) {
                return;
            }
            if (objectItems === false || objectItems === undefined) {
                setObjectItems(queryResult);
            }
        }
    }

    if (objectItems === false || objectItems === undefined) {
        return;
    }

    const defaultFormatter = (value) => {
        return value;
    };

    const defaultRowItemRenderer = (object) => {
        let k = '';

        if (objectItems.data.length > 0 && fields.length > 0) {
            k = object[fields[0]["field"]];
        }

        let row = fields.map(({field, formatter, rowSpan}) => {
            let currentFormatter = defaultFormatter;

            if (formatter !== undefined && formatter !== null) {
                currentFormatter = formatter;
            }
            let span;
            if (rowSpan === undefined || rowSpan === null) {
                span = 1;
            } else {
                span = rowSpan;
            }

            if (urlBase !== undefined) {
                return <td colSpan={span} key={"row_item_" + field + "_" + k}
                           className={"ct-body-cell"}>
                    <Link key={k + "_link"} to={urlBase + k} className={"ct-table-link"}>
                        {currentFormatter(object[field], object)}
                    </Link>
                </td>;
            } else {
                return <td colSpan={span} key={"row_item_" + field + "_" + k}
                           className={"ct-body-cell"}>
                    {currentFormatter(object[field], object)}
                </td>;
            }
        });

        return <tr className={"ct-body-row"} key={k}>
            {row}
        </tr>;

    };

    const defaultRowRenderer = () => {
        let currentItemRenderer = defaultRowItemRenderer;

        if (renderRowItemFnc !== undefined) {
            currentItemRenderer = renderRowItemFnc;
        }

        if (objectItems.data === undefined || objectItems.data.length === 0) {
            return <tr key={"no_data_row"} className={"ct-body-row"}>
                <td key={"row_item_no_data"}
                    className={"ct-body-cell"}>Brak danych
                </td>
            </tr>;
        }

        return objectItems.data.map(currentItemRenderer);
    };

    const defaultHeaderItemRenderer = ({title, headSpan}) => {
        if (title === null) {
            return;
        }

        let props = title.props;
        let tKey;

        if (props === undefined) {
            tKey = title;
        } else {
            tKey = props.tKey;
        }
        let span;

        if (headSpan === null || headSpan === undefined) {
            span = 1;
        } else {
            span = headSpan;
        }


        return <td colSpan={span} key={tKey} className={"ct-head-cell"}>{title}</td>;
    };

    const defaultHeaderRenderer = () => {

        let currentItemRenderer = defaultHeaderItemRenderer;

        if (renderHeaderItemFnc !== undefined) {
            currentItemRenderer = renderHeaderItemFnc;
        }

        const headers = fields.map(currentItemRenderer);

        return <tr key={"table_head_row"} className={"ct-head-row"}>
            {headers}
        </tr>;
    };

    const defaultPaginationRenderer = () => {
        if (paginationCallback === undefined || paginationCallback === null) {
            return;
        }

        return <div className={"col-12"}>
            <button>{'<<'}</button>
            <button>{'<'}</button>
            <button>{'>'}</button>
            <button>{'>>'}</button>
        </div>;
    };

    let currentHeaderRenderer;
    let currentRowRenderer;

    if (renderHeaderFnc === undefined) {
        currentHeaderRenderer = defaultHeaderRenderer;
    } else {
        currentHeaderRenderer = renderHeaderFnc;
    }

    if (renderRowsFnc === undefined) {
        currentRowRenderer = defaultRowRenderer;
    } else {
        currentRowRenderer = renderRowsFnc;
    }

    let tableHeader;

    if (useAsForm) {
        tableHeader = <h5>{tableName}</h5>;
    } else {
        tableHeader = <h1>{tableName}</h1>;
    }

    const defaultFilterFunction = (event) => {
        console.log(event.target.value);
    };

    let paginationHeader;

    if (paginationCallback === undefined || paginationCallback === null) {
        paginationHeader = '';
    }else{
        paginationHeader = <div className={"col-6 offset-6"}>
            <input
                placeholder={""}
                required={false}
                name={"table_search_" + tableName}
                className={"ct-form-text-input"}
                type={"text"}
                onChange={defaultFilterFunction}
            />
        </div>;
    }

    return (
        <center>
            <div className={"row"}>
                {tableHeader}
                {paginationHeader}
                <div className={"col-12"}>
                    <table className={"ct-table"}>
                        <thead className={"ct-head"}>
                        {currentHeaderRenderer()}
                        </thead>
                        <tbody className={"ct-body"}>
                        {currentRowRenderer()}
                        </tbody>
                        <tfoot className={"ct-foot"}>
                        <tr className={"ct-body-row"}>
                            <td colSpan={fields.length ?? 1} className={"ct-body-cell"}>Wszystkich
                                rekordów: {objectItems.data.length}</td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
                {defaultPaginationRenderer()}
            </div>
        </center>
    );
}