import {Translator} from "../Components/Translator";
import TableComponent from "../Components/TableComponent";
import axios from "axios";
import AxiosTableComponent from "../Components/AxiosTableComponent";


export function FriendsTable() {

    function axiosTest() {
        const token = localStorage.getItem('bearer-token');

        return axios.get('http://localhost/api/messenger/friends', {
            headers: {
                "X-Authorization": "Bearer " + token
            }
        });
    }

    const nameFormatter = (val, object) => {
        return object.party.base.name;
    };

    let objectFields =
        [
            {"field": "name", "title": <Translator tKey={'form.register.name'}/>, 'formatter': nameFormatter},
        ];

    return (
        <AxiosTableComponent
            fields={objectFields}
            urlBase={"/friend-c/"}
            useEffectCallback={axiosTest}
        />
    );
}