import {Route, Routes} from "react-router-dom";
import {AccountComponent} from "../Views/AccountComponent";
import {SingleCryptoComponent} from "../Views/SingleCryptoComponent";
import {SigningComponent} from "../Views/SigningComponent";
import {PaymentsComponent} from "../Views/PaymentsComponent";
import {ProductComponent} from "../Views/ProductComponent";
import {HomePageComponent} from "../Views/HomePageComponent";
import {KontaktComponent} from "../Views/KontaktComponent";
import {AboutComponent} from "../Views/AboutComponent";
import {_404Component} from "../Views/404Component";
import React, {useContext} from "react";
import {SecurityMap} from "./SecurityMap";
import {RegisterComponent} from "../Views/RegisterComponent";
import {LogoutComponent} from "../Views/LogoutComponent";
import {CommunicationComponent} from "../Views/CommunicationComponent";
import {ChargeComponent} from "../Views/ChargeComponent";
import {CurrentUser} from "../App";
import {ShieldComponent} from "../Components/ShieldComponent";
import {HandshakeComponent} from "../Components/HandshakeComponent";


export function AccessValidation(privileges, area) {
    try {
        let areaPart = area.split(".");
        let needle = SecurityMap

        areaPart.forEach(function (element) {
            needle = needle[element];
        });

        needle.forEach(function (value) {
            if (privileges.indexOf(value) === -1) {
                throw new Error();
            }
        });

    } catch (e) {
        return false;
    }

    return true;
}

export function IsNotHomePage() {
    return window.location.toString() !== "http://localhost/"
        && window.location.toString() !== "https://crypt0n.online/"
        && window.location.toString() !== "http://localhost"
        && window.location.toString() !== "https://crypt0n.online"
    ;
}

export function UserRouter() {
    const currentUser = useContext(CurrentUser);

    if (currentUser.id === 0 && IsNotHomePage()) {
        window.location = "/";
    }

    return (
        <Routes>
            <Route path={"/account/*"} element={<AccountComponent/>}/>

            <Route path={"/online/*"} element={<RegisterComponent/>}/>

            <Route path={"/logout"} element={<LogoutComponent/>}/>

            <Route path={"/handshake"} element={<HandshakeComponent/>}/>
            <Route path={"/shield"} element={<ShieldComponent/>}/>

            <Route path={"/crypto/*"} element={<SingleCryptoComponent/>}/>
            {/*<Route path={"/chat"} element={<ChatComponent/>}/>*/}
            <Route path={"/digital-sign"} element={<SigningComponent/>}/>

            <Route path={"/chat/*"} element={<CommunicationComponent/>}/>
            <Route path={"/payments"} element={<PaymentsComponent/>}/>
            <Route path={"/products/*"} element={<ProductComponent/>}/>
            <Route path={"/charge"} element={<ChargeComponent/>}/>

            <Route path={"/"} element={<HomePageComponent/>}/>
            <Route path={"/contact"} element={<KontaktComponent/>}/>
            <Route path={"/about"} element={<AboutComponent/>}/>

            <Route path={"*"} element={<_404Component/>}/>
        </Routes>
    );
}