import {FormComponent} from "../Components/FormComponent";
import {Translator} from "../Components/Translator";
import {useContext, useState} from "react";
import {CurrentUser} from "../App";
import {useMutation} from "@apollo/client";
import {ADD_FRIEND_MUTATION} from "../ApolloClient/Mutations/AddFriend";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const configuration = {
    "title": <Translator tKey={"form.title.invite"}/>, "inputs": [{
        "type": "file",
        "cssClass": "",
        "inputName": "file",
        "icon": <FontAwesomeIcon icon={"fa-solid fa-address-card"} className="blueIcon registerIcon ct-icon"></FontAwesomeIcon>
    }], "name": "account_from", "buttons": [{
        "type": "add"
    },]
}

export function AddFriendForm() {
    const currentUser = useContext(CurrentUser);

    const [addFriendMutation, {af_data, af_loading, af_error}] = useMutation(ADD_FRIEND_MUTATION);


    const handleRegister = (data, files) => {
        const file = files.pop();

        if (file === null || file === undefined){
            return;
        }

        addFriendMutation({
            variables: {
                _file: file
            }
        }).then(
            (r) => {
                console.log(r);
            }
        ).catch(
            (r) => {
                //console.log(r);
            }
        )
    };

    return (<FormComponent config={configuration} onSubmitCallback={handleRegister}/>);
}