import {ApolloClient, createHttpLink, InMemoryCache, useQuery} from '@apollo/client';
import {setContext} from "@apollo/client/link/context";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";


const httpLink = createHttpLink({
    uri: '/graphql',
});

const authLink = setContext((_, {headers}) => {
    const token = localStorage.getItem('bearer-token');

    return {
        headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : "",
        }
    }
});

export const gqlClient = new ApolloClient({
    link: authLink.concat(createUploadLink()),
    uri: 'https://crypt0n.online/graphql',
    cache: new InMemoryCache()
});

export function Query(query, key, _variables) {
    const {loading, error, data} = useQuery(query,{
        variables: _variables
    });

    if (loading) return false;

    if (error) return false;

    return data[key];
}