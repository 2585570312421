import './AboutStatic.css'
export function AboutStatic(){
    let logoSize = 250;
    return(
        <>
            <br/>
            <img className={"logo-key-about-static"} src={"logo_key.png"} alt={"crypt0n"} width={logoSize} height={logoSize}/>
            <br/><br/><br/>
            <p>Naszą misją jest dostarczanie prywatnośći w sieci.</p>
        </>
    );
}