import {useContext, useState} from "react";
import './MainMenuComponent.css'
import {CheckAccess, CurrentUser, HasAdminAccess} from "../App";
import {Link, Route, Routes} from "react-router-dom";
import {Translator} from "./Translator";
import {IsEchoInitialized} from "../EchoClient/EchoClient";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons";

function getNotifications(user, key) {
    let n = user.menuNotifications
    for (let i = 0; i < n.length; i++) {
        let c = n[i];
        if (c.name === key) {
            return c.count;
        }
    }
    return 0;
}

export default function MainMenuComponent() {
    const currentUser = useContext(CurrentUser);

    let currentNotification = {
        communication: getNotifications(currentUser, "communication"),
        encryption: getNotifications(currentUser, "encryption"),
        shop: getNotifications(currentUser, "shop"),
        account: getNotifications(currentUser, "account")
    }

    const [notification, setNotification] = useState(currentNotification);

    if (IsEchoInitialized()) {
        window.Echo.private(
            'user.notifications.' + currentUser.id.toString()
        )
            .listen(
                "UserStatusUpdated",
                (e) => {
                    setNotification({
                        communication: e.userNotifications[0].count,
                        encryption: e.userNotifications[1].count,
                        shop: e.userNotifications[2].count,
                        account: e.userNotifications[3].count
                    });
                }
            );
    }

    let adminMenu = [
        {
            "id": -99,
            "nazwa": "",
            "powiadomienia": 0,
            "url": "/",
            "icon": "crypt0n",
            "style": "menuButton"
        },
        {
            "id": 0,
            "nazwa": <Translator tKey={"menu.nav.communication"}/>,
            "powiadomienia": notification.communication,
            "url": "/chat/messages/all",
            "icon": "fa-comment-dollar",
            "style": "menuButton"
        },
        {
            "id": 1,
            "nazwa": <Translator tKey={"menu.nav.encryption"}/>,
            "powiadomienia": notification.encryption,
            "url": "/crypto/single/encrypt",
            "icon": "fa-fingerprint",
            "style": "menuButton"
        },
        {
            "id": 3,
            "nazwa": <Translator tKey={"menu.nav.accounts"}/>,
            "powiadomienia": notification.account,
            "url": "/admin/users",
            "icon": "fa-users-viewfinder",
            "style": "menuButton"
        },
        {
            "id": -98,
            "nazwa": <Translator tKey={"menu.nav.admin"}/>,
            "powiadomienia": 0,
            "url": "/admin/index",
            "icon": "fa-hammer",
            "style": "menuButton"
        }, {
            "id": -2,
            "icon": "account",
            "url": "/charge",
            "style": "menuChargeButton",
            'account': currentUser.account.netAmount,
            'inCurrencyAmount': currentUser.account.inCurrencyAmount,
            'currencySymbol': currentUser.account.currency.symbol
        }, {
            "id": -97,
            "nazwa": <Translator tKey={"menu.nav.logout"}/>,
            "powiadomienia": 0,
            "url": "/logout",
            "icon": "fa-right-from-bracket",
            "style": "logoutMenuButton"
        },
    ];

    let userMenu = [
        {
            "id": -1,
            "nazwa": "",
            "powiadomienia": 0,
            "url": "/",
            "icon": "crypt0n",
            "style": "menuButton"
        },
        {
            "id": 0,
            "nazwa": <Translator tKey={"menu.nav.communication"}/>,
            "powiadomienia": notification.communication,
            "url": "/chat/messages/all",
            "icon": "fa-comment-dots",
            "style": "menuButton"
        },
        {
            "id": 1,
            "nazwa": <Translator tKey={"menu.nav.encryption"}/>,
            "powiadomienia": notification.encryption,
            "url": "/crypto/single/encrypt",
            "icon": "fa-fingerprint",
            "style": "menuButton"
        },
        {
            "id": 2,
            "nazwa": <Translator tKey={"menu.nav.shop"}/>,
            "powiadomienia": notification.shop,
            "url": "/products/individual",
            "icon": "fa-store",
            "style": "menuButton"
        },
        {
            "id": 3,
            "nazwa": <Translator tKey={"menu.nav.account"}/>,
            "powiadomienia": notification.account,
            "url": "/account/payments",
            "icon": "fa-user",
            "style": "menuButton",
            "img": " "
        }, {
            "id": -2,
            "icon": "account",
            "url": "/charge",
            "style": "menuChargeButton",
            'account': currentUser.account.netAmount,
            'inCurrencyAmount': currentUser.account.inCurrencyAmount,
            'currencySymbol': currentUser.account.currency.symbol
        }, {
            "id": 4,
            "nazwa": <Translator tKey={"menu.nav.logout"}/>,
            "powiadomienia": 0,
            "url": "/logout",
            "icon": "fa-right-from-bracket",
            "style": "logoutMenuButton"
        },
    ];

    let guestMenu = [
        {
            "id": -1,
            "nazwa": "",
            "powiadomienia": 0,
            "url": "/",
            "icon": "crypt0n",
            "style": "menuButton"
        },
        {
            "id": 0,
            "nazwa": <Translator tKey={"menu.nav.about"}/>,
            "powiadomienia": 0,
            "url": "/about",
            "icon": "fa-circle-info",
            "style": "menuButton"
        },
        {
            "id": 1,
            "nazwa": <Translator tKey={"menu.nav.shop"}/>,
            "powiadomienia": 0,
            "url": "/products/individual",
            "icon": "fa-store",
            "style": "menuButton"
        },
        {
            "id": 2,
            "nazwa": <Translator tKey={"menu.nav.contact"}/>,
            "powiadomienia": 0,
            "url": "/contact",
            "icon": "fa-phone",
            "style": "menuButton"
        },
        {
            "id": 3,
            "nazwa": <Translator tKey={"menu.nav.signin"}/>,
            "powiadomienia": 0,
            "url": "/online/login",
            "icon": "fa-user",
            "style": "menuButton"
        },
    ];

    function render(_options) {
        return _options.map(({
                                 id,
                                 nazwa,
                                 powiadomienia,
                                 url,
                                 icon,
                                 style,
                                 img,
                                 account,
                                 inCurrencyAmount,
                                 currencySymbol
                             }) => {
            if (icon === 'crypt0n') {
                let logoSize = 48;
                return <Link key={id} className={"buttonLink"} to={url}>
                    <li key={id} className={style}>
                        <img alt={"crypt0n"} src={'/cr-logo2.png'} width={logoSize} height={logoSize}/>
                    </li>
                </Link>;
            } else if (icon === 'account') {
                let aInfo;
                if(inCurrencyAmount === 0){
                    aInfo = account+' ￠';
                }else{
                    aInfo = `${account} ￠ ( ${inCurrencyAmount} ${currencySymbol} )`;
                }
                return <Link key={id} className={"buttonLink"} to={url}>
                    <li key={id} className={style}>
                        {aInfo}
                    </li>
                </Link>;
            } else {
                if (parseInt(powiadomienia, 10) > 0) {
                    if (parseInt(powiadomienia, 10) > 99) {
                        powiadomienia = '99+';
                    }
                    return <Link key={id} className={"buttonLink"} to={url}>
                        <li key={id} className={style}>
                            {img ? <img className={"avatarImage"} src={'/kobietasobiegrabi.jpg'}
                                        alt={"avatar"}/> :
                                <FontAwesomeIcon icon={"fa-solid " + icon} className={"ct-icon"}/>} {nazwa}
                            <span className="badge rounded-pill cryptonColor">{powiadomienia}</span>
                        </li>
                    </Link>
                } else {
                    return <Link key={id} className={"buttonLink"} to={url}>
                        <li key={id} className={style}>
                            {img
                                ? <img className={"avatarImage"} src={'/kobietasobiegrabi.jpg'}
                                       alt={"avatar"}/>
                                : <FontAwesomeIcon icon={"fa-solid " + icon} className={"ct-icon"}/>} {nazwa}
                        </li>
                    </Link>
                }
            }
        })
    }

    const renderHomePageMenu = (user) => {
        let renderOption;

        if (user.loggedIn && HasAdminAccess(user.privileges)) {
            renderOption = adminMenu;
        } else if (user.loggedIn) {
            renderOption = userMenu;
        } else {
            renderOption = guestMenu;
        }

        return render(renderOption);
    };

    return (
        <ul className={"menuList"}>
            {renderHomePageMenu(currentUser)}
        </ul>
    );
}
