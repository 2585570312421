import {ViewComponent} from "../Components/ViewComponent";
import {Translator} from "../Components/Translator";
import {RegisterForm} from "../Forms/RegisterForm";
import LoginForm from "../Forms/LoginForm";
import {Route, Routes} from "react-router-dom";
import {_404Component} from "./404Component";

const tabs = [
    {
        "id": 0,
        "icon": "fa-user",
        "link" : "/online/login"
    },
    {
        "id": 1,
        "icon": "fa-address-card",
        "link" : "/online/register"
    }
];

const viewMenu = [
    {
        "title": "",
        "content": <LoginForm/>,
    }
];

const viewMenu2 = [
    {
        "title": <Translator tkey={"view.register.title"}/>,
        "content": <RegisterForm/>,
    }
];

export function RegisterComponent() {
    return (
        <Routes>
            <Route path={"/login"} element={<ViewComponent data={viewMenu} tabs={tabs}/>}/>
            <Route path={"/register"} element={<ViewComponent data={viewMenu2} tabs={tabs}/>}/>

            <Route path={"*"} element={<_404Component/>}/>
        </Routes>
    );
}