export const eng_language = {
    "menu": {
        "nav": {
            "logout": "Log out",
            "communication": "Communication",
            "encryption": "Encryption",
            "shop": "Products",
            "account": "Account",
            "accounts": "Accounts",
            "about": "About",
            "contact": "Contact",
            "signin": "Sign in",
            "register": "Register",
            "admin": "Administrator panel"
        }
    },
    "view": {
        "404": {
            "title": "404",
            "content": "Page not found"
        },
        "about": {
            "title": "About"
        },
        "account": {
            "title": "Account"
        },
        "charge": {
            "title": "Charge",
            "content": "Charge your account"
        },
        "friends": {
            "title": "Friends"
        },
        "chat": {
            "title": "Chats"
        },
        "products": {
            "title": {
                "main": "Products",
                "individual": "Individual solutions",
                "network": "Network solutions",
                "hardware": "Hardware solutions"
            }
        },
        "encryption": {
            "title": "Encryption"
        },
        "decryption": {
            "title": "Decryption"
        },
        "dsignatures": {
            "title": "Digital signatures"
        },
        "payments": {
            "title": "Payments"
        },
        "contact": {
            "title": "Contact"
        },
        "register": {
            "title": "Register"
        },
        "login": {
            "button": {
                "login": "Log in",
                "forgot": "Forgot password?"
            }
        }
    },
    "form": {
        "title": {
            "register": "Register",
            "signin": "Login",
            "account": "Account",
            "invite": "Add friend using invitation",
            "invite_create": "Create invitation"
        },
        "register": {
            "name": "Name",
            "email": "Email",
            "password": "Password",
            "passwordConfirmation": "Password confirmation",
            "rules": "I have accepted rules."
        },
        "common": {
            "button": {
                "register": "Register",
                "login": "Log in",
                "forgot": "Forgot password?",
                "save": "Save",
                "cancel": "Cancel",
                "encrypt": "Encrypt",
                "decrypt": "Decrypt",
                "generate": "Generate",
                "download": "Download",
                "add": "Add"
            },
            "placeholders": {
                "email": "Email",
                "password": "Password"
            }
        },
        "user": {
            "language": "Language",
            "privileges": "Privileges",
            "status": "Status"
        },
        "crypto": {
            "encrypt": "Encrypt text",
            "decrypt": "Decrypt text",
            "algorithm": "Algorithm"
        }
    },
    "languages": {
        "pl": "Polish",
        "eng": "English"
    },
    "algorithms": {
        "rsa4096_sha512": "RSA 4096 SHA512",
        "rsa4096_sha256": "RSA 4096 SHA256",
        "rsa2048_sha512": "RSA 2048 SHA512",
        "rsa2048_sha256": "RSA 2048 SHA256",
        "aes256cbc": "AES_256_CBC"
    }
};