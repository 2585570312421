import {redirect, Route, Routes} from "react-router-dom";
import {IndexComponent} from "../Admin/IndexComponent";
import {useContext} from "react";
import {CurrentUser} from "../App";
import {AccessValidation} from "./UserRouter";
import {_404Component} from "../Views/404Component";
import {UsersComponent} from "../Admin/User/UsersComponent";
import {UserComponent} from "../Admin/User/UserComponent";

export function AdminRouter() {
    const currentUser = useContext(CurrentUser);

    if (!AccessValidation(currentUser.privileges,"admin.index")){
        window.location = "/";
    }

    return (
        <Routes>
            <Route path={"/index"} element={<IndexComponent/>}/>

            <Route path={"/users"} element={<UsersComponent/>}/>
            <Route path={"/user/:id"} element={<UserComponent/>}/>

            <Route path={"*"} element={<_404Component/>}/>
        </Routes>
    );
}