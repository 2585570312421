import {GET_USERS} from "../ApolloClient/Queries/Users";
import TableComponent from "../Components/TableComponent";
import {Translator} from "../Components/Translator";
import {languageFormatter} from "../Components/Formatter";

export function SelectListComponent({config}) {

    return (
        <div className={"row"}>
                <TableComponent
                    useAsForm={true}
                    tableName={<Translator tKey={config.title}/>}
                    query={config.graphql.query}
                    queryField={config.graphql.queryField}
                    fields={config.objectFields}
                    urlBase={config.urlBase}
                    renderHeaderItemFnc={config.renderHeaderItemFnc}
                    renderRowItemFnc={config.renderRowItemFnc}
                    renderHeaderFnc={config.renderHeaderFnc}
                    renderRowsFnc={config.renderRowsFnc}
                />
        </div>
    );
}