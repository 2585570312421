export const languageFormatter = (value) => {
    if (value === "pl"){
        return (
            <>🇵🇱</>
        );
    }
    if (value === "eng"){
        return (
            <>🇬🇧</>
        );
    }

    return <>-</>;
};

export const languageSelectFormatter = (value, object) => {
    let ret = value;

    if (object.id === "pl"){
        ret += " 🇵🇱";
    }
    if (object.id === "eng"){
        ret += " 🇬🇧";
    }

    return ret;
};