export const pl_language = {
    "menu": {
        "nav": {
            "logout": "Wyloguj",
            "communication": "Komunikacja",
            "encryption": "Szyfrowanie",
            "dsignatures": "Cyfrowe podpisy",
            "shop": "Produkty",
            "payments": "Płatności",
            "account": "Konto",
            "accounts": "Konta użytkowników",
            "about": "O nas",
            "contact": "Kontakt",
            "signin": "Logowanie",
            "register": "Rejestracja",
            "admin": "Panel administratora"
        }
    },
    "view": {
        "404": {
            "title": "404",
            "content": "Nie znaleziono strony"
        },
        "about": {
            "title": "O nas"
        },
        "account": {
            "title": "Konto"
        },
        "charge":{
            "title": "Doładowanie",
            "content": "Doładuj swoje konto"
        },
        "friends": {
            "title": "Znajomi"
        },
        "chat": {
            "title": "Czaty"
        },
        "products": {
            "title": {
                "main": "Produkty",
                "individual": "Rozwiązania indywidualne",
                "network": "Rozwiązania sieciowe",
                "hardware": "Rozwiązania sprzętowe"
            }
        },
        "encryption": {
            "title": "Szyfrowanie"
        },
        "decryption": {
            "title": "Deszyfrowanie"
        },
        "dsignatures": {
            "title": "Cyfrowe podpisy"
        },
        "payments": {
            "title": "Płatności"
        },
        "contact": {
            "title": "Kontakt"
        },
        "register": {
            "title": "Rejestracja"
        },
        "login": {
            "button": {
                "login": "Zaloguj",
                "forgot": "Zapomniałeś hasła?"
            }
        }
    },
    "form": {
        "title": {
            "register": "Rejestracja",
            "signin": "Logowanie",
            "account": "Konto",
            "invite": "Dodaj znajomego za pomocą zaproszenia",
            "invite_create": "Wygeneruj zaproszenie"
        },
        "register": {
            "name": "Nick",
            "email": "Email",
            "password": "Hasło",
            "passwordConfirmation": "Potwierdzenie hasła",
            "rules": "Akceptuję regulamin."
        },
        "common": {
            "button": {
                "register": "Zarejestruj",
                "login": "Zaloguj",
                "forgot": "Zapomniałeś hasła?",
                "save": "Zapisz",
                "cancel": "Anuluj",
                "encrypt": "Szyfruj",
                "decrypt": "Odszyfruj",
                "generate": "Generuj",
                "download": "Pobierz",
                "add": "Dodaj"
            },
            "placeholders": {
                "email": "Email",
                "password": "Hasło"
            }
        },
        "user": {
            "language": "Język",
            "privileges" : "Uprawnienia",
            "status": "Status"
        },
        "crypto":{
            "encrypt": "Wprowadź tekst do zakodowania",
            "decrypt": "Wprowadź tekst do zdekodowania",
            "algorithm" : "Algorytm"
        }
    },
    "languages":{
        "pl" : "Polski",
        "eng": "Angielski"
    },
    "algorithms": {
        "rsa4096_sha512": "RSA 4096 SHA512",
        "rsa4096_sha256": "RSA 4096 SHA256",
        "rsa2048_sha512": "RSA 2048 SHA512",
        "rsa2048_sha256": "RSA 2048 SHA256",
        "aes256cbc": "AES_256_CBC"
    }
};