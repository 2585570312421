import {ViewComponent} from "../Components/ViewComponent";
import {Translator} from "../Components/Translator";
import {AboutStatic} from "../Statics/AboutStatic";

const tabs = [
    {
        "id": 0,
        "icon": "fa-circle-info"
    }
];

const viewMenu = [{
    "title": <Translator tKey={"view.about.title"}/>,
    "content": <AboutStatic/>,
}];

export function AboutComponent() {
    return (
        <ViewComponent data={viewMenu} tabs={tabs}/>
    );
}