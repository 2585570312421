import {ViewComponent} from "../Components/ViewComponent";
import {Translator} from "../Components/Translator";
import {tab} from "@testing-library/user-event/dist/tab";

const tabs = [
    {
        "id": 0,
        "icon": "fa-dollar"
    }
];
const viewMenu = [{
    "title": <Translator tKey={"view.charge.title"}/>,
    "content": <Translator tKey={"view.charge.content"}/>,
}];

export function ChargeComponent() {
    return (
        <ViewComponent data={viewMenu} tabs={tabs}/>
    );
}