import {translateString, Translator} from "../Components/Translator";
import {useContext} from "react";
import {CurrentUser} from "../App";

export function SelectComponent({config}) {

    const currentUser = useContext(CurrentUser);

    const defaultFormatter = (value) => {
        return value;
    };

    let currentFormatter = defaultFormatter;

    if (config.formatter !== undefined){
        currentFormatter = config.formatter;
    }
    const prepareOptions = (options) => {
        return options.map((option) => {
            return (
                <option key={option.id} label={currentFormatter(translateString(option.text, currentUser.language), option)} value={option.id}/>
            );
        });
    }

    return (
        <div className={"row"}>
            <div className={"ct-form-select-box"}>
                {config.icon}
                {config.label}
                <select defaultValue={config.value} name={config.inputName}>
                    {prepareOptions(config.options)}
                </select>
            </div>
        </div>
    );
}