import {FormComponent} from "../Components/FormComponent";
import {Translator} from "../Components/Translator";
import {useState} from "react";
import {useLazyQuery} from "@apollo/client";
import {DECRYPT_RSA_QUERY} from "../ApolloClient/Queries/decryptRSA";

const configuration = {
    // "title": <Translator tKey={"view.encryption.title"}/>,
    "inputs": [
        {
            "type": "textarea",
            "inputName": "crypto",
            "label": "form.crypto.decrypt"
        },
        {
            "type": "select",
            "inputName": "algorithm",
            "label": <Translator tKey={"form.crypto.algorithm"}/>,
            "options": [
                {
                    "id": "rsa4096_sha512",
                    "text": "algorithms.rsa4096_sha512",
                    "selected": true
                }
            ]
        },
        {
            "type": "textarea",
            "inputName": "output",
            "readOnly": false
        }
    ],
    "name": "crypto_from",
    "buttons": [
        {
            "type": "decrypt"
        },
    ]
}

export function SingleDecryptCryptoForm() {
    const [decryptRSA, {loading, error, data}] = useLazyQuery(DECRYPT_RSA_QUERY);
    const [outputText, setOutputText] = useState("");
    if (loading) {
        return;
    }

    if (error) {
        return;
    }
    const handleRegister = (data) => {
        let rsa = decryptRSA({variables: {_message: data.crypto}});

        rsa.then(function (response) {
            if (response.data !== undefined) {
                setOutputText(response.data.decryptRSA.message);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    configuration.inputs[2].value = outputText;

    return (
        <FormComponent config={configuration} onSubmitCallback={handleRegister}/>
    );
}