import {ViewComponent} from "../Components/ViewComponent";
import {Translator} from "../Components/Translator";
import {tab} from "@testing-library/user-event/dist/tab";

const tabs = [
    {
        "id": 0,
        "icon": "fa-ghost"
    }
];

const viewMenu = [
    {
        "title": <Translator tKey={"view.404.title"}/>,
        "content": <Translator tKey={"view.404.content"}/>,
    }
];

export function _404Component() {
    return (
        <ViewComponent data={viewMenu} tabs={tabs}/>
    );
}