import Echo from "laravel-echo";
import {useContext} from "react";
import {CurrentUser, updateUser} from "../App";

window.Pusher = require('pusher-js');

const __token = localStorage.getItem('bearer-token');

export function startWebsocketClient(user) {
    //console.log(__token);
    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: 'crypt0n',
        wsHost: window.location.hostname,
        wsPort: 6001,
        wssPort: 6001,
        forceTLS: false,
        // encrypted: true
        disableStats: true,
        auth: {
            headers: {
                'X-Authorization': 'Bearer ' + __token,
                'X-App-ID': 'app'
            }
        },
        //authEndpoint: 'http://localhost/broadcasting/auth', // OR
        // authEndpoint: 'http://api.awesomeapp.test/broadcasting/auth',
        enabledTransports: ['ws', 'flash']
    });
}

export function IsEchoInitialized() {
    const currentUser = useContext(CurrentUser);

    return typeof window.Echo !== 'undefined' && currentUser.id > 0 && currentUser.loggedIn;
}
