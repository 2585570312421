import {ViewComponent} from "../../Components/ViewComponent";
import {AdminIndexStatic} from "../../Statics/AdminIndexStatic";
import {UserTable} from "./UserTable";

const tabs = [
    {
        "id": 0,
        "icon": "fa-users-viewfinder"
    }
]

const viewMenu = [{
    "title": "",
    "content": <UserTable/>,
}];

export function UsersComponent() {
    return (
        <ViewComponent data={viewMenu} tabs={tabs}/>
    );
}