import {ViewComponent} from "../Components/ViewComponent";
import {AdminIndexStatic} from "../Statics/AdminIndexStatic";

const tabs = [
    {
        "id": 0,
        "icon": "fa-hammer"
    }
];

const viewMenu = [{
    "title": "crypt0n",
    "content": <AdminIndexStatic/>,
}];

export function IndexComponent() {
    return (
        <ViewComponent data={viewMenu} tabs={tabs}/>
    );
}