export function CheckBoxComponent({config}) {
    return (
        <div className={"row"}>
            <div>
                <input key={config.inputName + "_input"} name={config.inputName} className={config.cssClass + " ct-form-checkbox"}
                       type={"checkbox"}/>
                {config.label}
            </div>
        </div>
    );
}