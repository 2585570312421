import TableComponent from "../../Components/TableComponent";
import {GET_USERS} from "../../ApolloClient/Queries/Users";
import {Translator} from "../../Components/Translator";
import {languageFormatter} from "../../Components/Formatter";
import {HasAdminAccess, HasModeratorAccess} from "../../App";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export function UserTable() {
    const accountFormatter = (val, object) => {
        return (
            <>
                {object.account.netAmount} ￠ ({object.account.inCurrencyAmount} {object.account.currency.symbol})
            </>
        );
    };

    const languageF = (val, object) => {
        return (
            <>{languageFormatter(val)}</>
        );
    };

    const privilegeFormatter = (val, object) => {
        if (HasAdminAccess(object.privileges)) {
            return (
                <>
                    <FontAwesomeIcon title={"Admin"}
                                     icon="fa-solid fa-person-rifle"
                                     className={"ct-icon ct-icon-user-status"}></FontAwesomeIcon>
                </>
            );
        }

        if (HasModeratorAccess(object.privileges)) {
            return (
                <>
                    <FontAwesomeIcon title={"Moderator"}
                                     icon="fa-solid fa-person-military-pointing"
                                     className={"ct-icon ct-icon-user-status"}></FontAwesomeIcon>
                </>
            );
        }

        return (
            <><FontAwesomeIcon title={"User"}
                               icon="fa-solid fa-person"
                               className={"ct-icon ct-icon-user-status"}></FontAwesomeIcon></>
        );
    };

    let objectFields =
        [
            {"field": "id", "title": "ID"},
            {"field": "name", "title": <Translator tKey={'form.register.name'}/>},
            {"field": "email", "title": <Translator tKey={'form.register.email'}/>},
            {
                "field": "account",
                "title": <Translator tKey={'form.user.status'}/>,
                "formatter": accountFormatter,
                "headSpan": 3
            },
            {"field": "language", "title": null, "formatter": languageF},
            {"field": "privilege", "title": null, "formatter": privilegeFormatter},
        ];

    return (
        <TableComponent
            tableName={"Użytkownicy"}
            query={GET_USERS}
            queryField={'users'}
            fields={objectFields}
            urlBase={"/admin/user/"}
            paginationCallback={"a"}
        />
    );
}