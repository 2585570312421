import {FormComponent} from "../Components/FormComponent";
import {Translator} from "../Components/Translator";
import {useContext, useState} from "react";
import {CurrentUser} from "../App";
import {useLazyQuery} from "@apollo/client";
import {LOGIN_QUERY} from "../ApolloClient/Queries/Login";
import {CREATE_INVITATION_QUERY} from "../ApolloClient/Queries/CreateInvitation";
import fileDownload from "js-file-download";


const configuration = {
    "title": <Translator tKey={"form.title.invite_create"}/>, "inputs": [], "name": "account_from", "buttons": [{
        "type": "generate"
    },]
}

export function CreateInvitationForm() {
    const [createInvitation, {loading, error, data}] = useLazyQuery(CREATE_INVITATION_QUERY);
    if (loading) {
        return;
    }

    if (error) {
        return;
    }

    const handleRegister = (data) => {
        let createQ = createInvitation();

        createQ.then(function (response) {
            if(response.data.generateInvitation !== undefined && response.data.generateInvitation.code === 0){
                fileDownload(response.data.generateInvitation.message,"personalInvitation.txt");
            }
        }).catch(function (error) {
            console.log(error);
        });
    };

    return (<FormComponent config={configuration} onSubmitCallback={handleRegister}/>);
}