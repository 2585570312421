import {ViewComponent} from "../../Components/ViewComponent";
import {Translator} from "../../Components/Translator";
import {AccountForm} from "../../Forms/AccountForm";

const tabs = [
    {
        "id": 0,
        "icon": "fa-user-pen"
    }
];

const viewMenu = [
    {
        "title": "",
        "content": <AccountForm isAdminMode={true}/>,
    }
];

export function UserComponent() {
    return (
        <ViewComponent data={viewMenu} tabs={tabs}/>
    );
}