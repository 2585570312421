import {gql} from "@apollo/client";

export const LOGIN_QUERY = gql`
    query LoginQuery($_email: String!,$_password: String!){
        login(email: $_email, password:$_password){
            token
        }
    }
`;

export const LOGOUT_QUERY = gql`
    query LogoutQuery{
        logout{
            message
        }
    }
`;
