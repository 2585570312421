import {ViewComponent} from "../Components/ViewComponent";
import {Translator} from "../Components/Translator";
import {AccountForm} from "../Forms/AccountForm";
import {Route, Routes} from "react-router-dom";
import {_404Component} from "./404Component";

const tabs = [
    {
        "id": 0,
        "icon": "fa-dollar-sign",
        "link": "/account/payments"
    },
    {
        "id": 1,
        "icon": "fa-user-pen",
        "link": "/account/settings"
    },
];

const viewMenu = [
    {
        "title": <Translator tKey={"view.payments.title"}/>,
        "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque fermentum nisi sed massa semper, eu lacinia nibh sagittis.",
    }
];
const viewMenuSettings = [
    {
        "title": "",
        "content": <AccountForm isAdminMode={false}/>,
    }
];

export function AccountComponent() {

    return (
        <Routes>
            <Route path={"/payments"} element={<ViewComponent data={viewMenu} tabs={tabs}/>}/>
            <Route path={"/settings"} element={<ViewComponent data={viewMenuSettings} tabs={tabs}/>}/>

            <Route path={"*"} element={<_404Component/>}/>
        </Routes>
    );
}