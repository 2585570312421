import {ERROR_KEY, FormComponent} from "../Components/FormComponent";
import {Translator} from "../Components/Translator";
import {useMutation} from "@apollo/client";
import {REGISTER_MUTATION} from "../ApolloClient/Mutations/Register";
import './Forms.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const configuration = {
    "title": <Translator tKey={"form.title.register"}/>,
    "inputs": [
        {
            "type": "email",
            "cssClass": "",
            "inputName": "email",
            "icon": <FontAwesomeIcon icon={"fa-solid fa-at"} className={"blueIcon registerIcon ct-icon"}/>,
            "placeholder": "form.register.email",
            "required": true
        },
        {
            "type": "password",
            "cssClass": "",
            "inputName": "password",
            "icon": <FontAwesomeIcon icon={"fa-solid fa-lock"} className={"blueIcon registerIcon ct-icon"}/>,
            "placeholder": "form.register.password",
            "required": true
        },
        {
            "type": "password",
            "cssClass": "",
            "inputName": "passwordConfirmation",
            "placeholder": "form.register.passwordConfirmation",
            "icon": <FontAwesomeIcon icon={"fa-solid fa-lock"} className={"blueIcon registerIcon ct-icon"}/>,
            "required": true
        },
        {
            "type": "checkbox",
            "cssClass": "",
            "inputName": "rulesAccepted",
            "label": <Translator tKey={"form.register.rules"}/>
        },
    ],
    "name": "register_from",
    "buttons": [
        {
            "type": "register"
        }
    ]
}

export function RegisterForm() {
    const [mutateFunction, {data, loading, error}] = useMutation(REGISTER_MUTATION);

    const handleRegister = (data,files,errorOutput) => {
        mutateFunction({variables: {_email: data.email, _password: data.password}}).then(
            (r) => {
                let operation = r.data.register;
                if (operation.code === 0) {
                    window.location = "/login";
                }else {
                    errorOutput(operation);
                }
            }
        ).catch(
            (r) => {
                console.log(r);
            }
        )

    }

    return (
        <FormComponent config={configuration} onSubmitCallback={handleRegister}/>
    );
}