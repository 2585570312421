const IS_ADMIN = "IS_ADMIN";
const IS_USER = "IS_USER";
export const SecurityMap = {
    "admin": {
        "index": [
            IS_ADMIN
        ]
    },
    "user":{
        "messages":{
            "index":[
                IS_USER
            ]
        }
    },
    "menu": [],
    "navbar": [],
}


