import {useContext} from "react";
import {CurrentUser} from "../App";
import {Query} from "../ApolloClient/Client";
import {HANDSHAKE_INIT_QUERY} from "../ApolloClient/Queries/InitHandshake";
import CryptoJS from 'crypto-js';
import forge from 'node-forge';


const generateSymmetricKey = () => {
    return 'tajnyKlucz';
    const key = CryptoJS.lib.WordArray.random(256 / 8); // 256-bit key
    const keyBase64 = CryptoJS.enc.Base64.stringify(key);
    return keyBase64;
};

function generateKeys(keySize = 2048) {
    // Generowanie pary kluczy
    const keypair = forge.pki.rsa.generateKeyPair({ bits: keySize, e: 0x10001 });

    const publicKeyPem = forge.pki.publicKeyToPem(keypair.publicKey);
    const privateKeyPem = forge.pki.privateKeyToPem(keypair.privateKey);

    return {publicKeyPem, privateKeyPem};
}

/*function encryptWithRSA(publicKeyPEM, plaintext) {
    const publicKey = forge.pki.publicKeyFromPem(publicKeyPEM);

    // Szyfrowanie za pomocą RSA
    const encryptedSecret = publicKey.encrypt(plaintext, 'RSA-OAEP');

    return forge.util.encode64(encryptedSecret);
}*/

function encryptWithRSA(publicKeyPEM, plaintext) {
    // Szyfrowanie wiadomości kluczem publicznym z użyciem OEAP
    const publicKey = forge.pki.publicKeyFromPem(publicKeyPEM);
    const encrypted = publicKey.encrypt(plaintext, 'RSA-OAEP', {
        md: forge.md.sha256.create(),
        mgf1: forge.mgf.mgf1.create()
    });
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encrypted));
}

function decryptWithRSA(publicKeyPEM, plaintext) {
    // Odszyfrowanie wiadomości kluczem prywatnym z użyciem OEAP
    const privateKey = forge.pki.privateKeyFromPem(privateKey);
    const encryptedBytes = CryptoJS.enc.Base64.parse(plaintext).toString(CryptoJS.enc.Utf8);
    const decrypted = privateKey.decrypt(encryptedBytes, 'RSA-OAEP', {
        md: forge.md.sha256.create(),
        mgf1: forge.mgf.mgf1.create()
    });
    return decrypted;
}


export function HandshakeComponent({cipherText}) {
    const currentUser = useContext(CurrentUser);
//Turn on shield
    /////////////////////////////


    const makeHandshake = async () => {
        let defaultShield = {
            handshake: ""
        }

        let handshake = Query(HANDSHAKE_INIT_QUERY, "handshakeInit");

        if (handshake === false) {
            return; // graphQl connection is not ready
        }
        if (handshake !== null && handshake.publicRSAKey !== null) {
            const secret = generateSymmetricKey();
            localStorage.setItem('dsh', secret);
            localStorage.setItem('hid', handshake.handshakeId);

            return encryptWithRSA(handshake.publicRSAKey, secret);
        }
    };


    makeHandshake().then((result) => {
        if (result !== undefined) {
            localStorage.setItem('esh', result);
            window.location = '/shield';
        }
    });
    ////////////////////////////
    return (
        <></>
    );
}
